<template>
	<div>
		<con-tab @TwoClick="TwoClick"></con-tab>
		<!--政府云-->
		<zhengfuyun-Form></zhengfuyun-Form>
	</div>
</template>

<script>
import ConTab from '../../components/common/ConTab';
import zhengfuyunForm from './base/zhengfuyunForm.vue';
export default {
	name: 'zhengfuyun',
	data() {
		return {};
	},
	created() {},
	methods: {
		TwoClick(item) {
			//console.log(item);
			this.ConList = item;
		}
	},
	components: {
		ConTab,
		zhengfuyunForm
	}
};
</script>

<style scoped></style>
