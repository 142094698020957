<template>
	<div class="governmentss">
		<div class="container">
			<div class="header">
				<h3>万达开科技创新数据云平台</h3>
				<p>{{ nowDate }}{{ nowTime }}</p>
			</div>
			<div class="main">
				<div class="box leftCon">
					<!-- 左一 -->
					<div class="tabs one tbga">
						<div class="tabCon">
							<div class="headline">
								<img src="../../../assets/img/images/bang_03.png" class="bangpic" alt="">
								<h3>国家高新技术企业、科技型中小企业展示</h3>
							</div>
							<vue-seamless-scroll :data="listData1" ref="listData1" :class-option="optionHover"
								class="list" style="height: 205px;">
								<ul class="item">
									<li v-for="item in listData1">
										<i></i>
										<p>{{ item.name }}</p>
										<span>查看详情 &gt;</span>
									</li>
								</ul>
							</vue-seamless-scroll>
						</div>
					</div>
					<!-- 左二 -->
					<div class="tabs two tbga" style="margin: 10px 0;">

						<div class="tabCon">
							<div class="headline">
								<img src="../../../assets/img/images/bang_03.png" class="bangpic" alt="">
								<h3>科技成果展示</h3>
							</div>
							<vue-seamless-scroll :data="listData2" ref="listData2" :class-option="optionHover"
								class="list" style="height: 216px;">
								<ul class="item">
									<li v-for="item in listData2">
										<div class="twoTit">
											<i></i>
											<p>{{ item.name }}</p>
										</div>
										<span>所属地市：{{ item.city }}</span>
										<span>研发单位：{{ item.education }}</span>
									</li>
								</ul>
							</vue-seamless-scroll>
						</div>
					</div>
					<!-- 左三 -->
					<div class="tabs tbgb">

						<div class="tabCon">
							<div class="headline" style="justify-content: space-between;">
								<img src="../../../assets/img/images/bang_03.png" class="bangpic" alt="">
								<h3 style="flex-grow: 1;">载体分布情况</h3>
								<el-tabs class="select" v-model="tabValue" @tab-click="handleClick"
									style="height: 24px; ">
									<el-tab-pane label="众创空间" name="0"></el-tab-pane>
									<el-tab-pane label="孵化器" name="1"></el-tab-pane>
								</el-tabs>
							</div>
							<div class="list" style="height: 273px;">

								<div id="ECharts1" ref="ECharts1" :style="{ width: '100%', height: '90%' }"></div>
							</div>
						</div>
					</div>
				</div>
				<div class="box centerCon">
					<div id="ECharts2" ref="ECharts2" style="width: 100%; height: 881px;"></div>
				</div>
				<div class="box rightCon">
					<!-- 右一 -->
					<div class="tabs two tbga">

						<div class="tabCon">
							<div class="headline">
								<img src="../../../assets/img/images/bang_03.png" class="bangpic" alt="">
								<h3>科技服务资源</h3>
							</div>
							<vue-seamless-scroll :data="listData3" :class-option="optionHover" class="list"
								style="height: 210px;">
								<ul class="item">
									<li v-for="item in listData3">
										<div class="twoTit">
											<i></i>
											<p>{{ item.name }}</p>
										</div>
										<span>所属地市：{{ item.city }}</span>
									</li>
								</ul>
							</vue-seamless-scroll>
						</div>
					</div>
					<!-- 右二 -->
					<div class="tabs two tbga" style="margin: 10px 0;">
						<div class="tabCon">
							<div class="headline">
								<img src="../../../assets/img/images/bang_03.png" class="bangpic" alt="">
								<h3>技术合同登记情况</h3>
							</div>
							<vue-seamless-scroll :data="listData4" :class-option="optionHover" class="list"
								style="height: 183px;">
								<ul class="item">
									<li v-for="item in listData4">
										<div class="twoTit">
											<i></i>
											<p>{{ item.name }}</p>
										</div>
										<span>技术合同登记额：{{ item.education }}</span>
									</li>
								</ul>
							</vue-seamless-scroll>
						</div>
					</div>
					<!-- 右三 -->
					<div class="tabs two tbgb">
						<div class="tabCon">
							<div class="headline">
								<img src="../../../assets/img/images/bang_03.png" class="bangpic" alt="">
								<h3>研发投入情况</h3>
							</div>
							<div class="list" style="height: 340px;">
								<div class="select" >
									<el-select v-model="cityValue" @change="selectClass">
										<el-option v-for="item in cityData" :key="item.value" :label="item.label"
											:value="item.value"></el-option>
									</el-select>
								</div>
								<div id="ECharts3" ref="ECharts3" :style="{ width: '100%', height: '90%' }"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		maps
	} from '../../../common/js/dazhou';
	import {
		wandlist,
		wandblist,
		wandzhong,
		wandfuhua,
		wanddlist,
		wandalist,
		wandclist,
		wandkeyan
	} from '../../../common/js/api';
	export default {
		name: 'enterpriseCloud',
		components: {},
		data() {
			return {
				nowDate: null, //存放年月日变量
				nowTime: null, //存放时分秒变量
				timer: '', //定义一个定时器的变量
				currentTime: new Date(), // 获取当前时间
				listData1: [],
				listData2: [],
				listData3: [],
				listData4: [],
				tabValue: '0',
				cityData: [],
				// cityData: [{ id: 1, value: '达州市', label: '达州市' }, { id: 2, value: '万州区', label: '万州区' }, { id: 3, value: '开州区', label: '开州区' }],
				cityValue: '达州市',
				maps: '',
				mark: 0
			};
		},
		created() {
			// 获取时间
			this.timer = setInterval(this.getTime, 1000);
			// 国家技术
			this.wandlist();
			// 科技成果
			this.wandblist();
			// 载体分布情况 - init
			this.loadData1(this.tabValue);
			// 科技服务
			this.wandalist();
			// 技术合同
			this.wandclist();
			// 研发投入情况 - init
			this.loadData3(this.cityValue);
		},
		computed: {
			optionHover() {
				return {
					step: 0.3, //速度，值越大，速度越快
					hoverStop: true, //鼠标悬停效果，false为关闭该效果
					// singleHeight: 26, //单行停顿
					// waitTime: 2500 //单行停顿的时间
					limitMoveNum: 1 // 开始无缝滚动的数据量
				};
			}
		},
		mounted() {
			console.log('333');
			// 获取时间
			this.getTime();
			// 地图 - init
			this.loadData2();
		},
		methods: {
			// 获取时间
			getTime() {
				const date = new Date();
				const year = date.getFullYear();
				const month = date.getMonth() + 1;
				const day = date.getDate();
				const hour = date.getHours();
				const minute = date.getMinutes();
				const second = date.getSeconds();
				const str = '';
				if (this.hour > 12) {
					this.hour -= 12;
					// this.str = ' PM';
					this.str = ' ';
				} else {
					// this.str = ' AM';
					this.str = ' ';
				}
				this.month = check(month);
				this.day = check(day);
				this.hour = check(hour);
				this.minute = check(minute);
				this.second = check(second);

				function check(i) {
					const num = i < 10 ? '0' + i : i;
					return num;
				}
				this.nowDate = year + '年' + this.month + '月' + this.day + '日';
				this.nowTime = this.hour + ':' + this.minute + ':' + this.second + this.str;
			},
			// 国家技术
			wandlist() {
				wandlist()
					.then(res => {
						this.listData1 = res.data;
					})
					.catch(() => {
						this.listData1 = [];
					});
			},
			// 科技成果
			wandblist() {
				wandblist()
					.then(res => {
						this.listData2 = res.data;
					})
					.catch(() => {
						this.listData2 = [];
					});
			},
			// 载体分布情况 - data
			loadData1(tabValue) {
				var data = [];
				if (tabValue == '0') {
					wandzhong()
						.then(res => {
							res.data.map(item => {
								data.push({
									value: Number(item.num),
									name: item.name
								});
							});
							this.$nextTick(function() {
								// 众创空间 - 图表
								this.ECharts1(data);
							});
						})
						.catch(() => {
							data = [];
						});
				} else if (tabValue == '1') {
					wandfuhua()
						.then(res => {
							res.data.map(item => {
								return (item.value = item.num);
							});
							data = res.data;
							this.$nextTick(function() {
								// 孵化器 - 图表
								this.ECharts1(data);
							});
						})
						.catch(() => {
							data = [];
						});
				}
			},
			// 载体分布情况 - 选择类型
			handleClick(tab, event) {
				this.tabValue = tab.index;
				this.loadData1(this.tabValue);
			},
			// 载体分布情况 - 图表
			ECharts1(data) {
						let ECharts = this.$echarts.init(this.$refs.ECharts1);
						ECharts.setOption({
							tooltip: {},
							color: ['#5087ec', '#68bbc4', '#58a55c', '#f2bd42', '#ee752f', '#d95040', '#5087ec', '#68bbc4', '#58a55c', '#f2bd42'],
							legend: {
								show: false
							},
							label: {
								color: '#fff'
							},
							top: 40,
							series: [
								{
									type: 'pie',
									radius: ['50%', '70%'],
									roundCap: true,
									itemStyle: {
										borderRadius: 40
									},
									emphasis: {
										label: {
											show: true,
											position: 'center',
											textStyle: {
												fontSize: '10',
												fontWeight: 'bold'
											}
										}
									},
									labelLine: {
										show: true,
										length: 10,
										length2: 15
									},
									data: data
								}
							]
						});
						window.addEventListener('resize', function() {
							ECharts.resize();
						});
					},
			// 地图 - data
			loadData2() {
				this.maps = maps();
				this.$echarts.registerMap('dazhou', this.maps);
				let ECharts = this.$echarts.init(this.$refs.ECharts2);
				let data = [];
				wanddlist()
					.then(res => {
						res.data.forEach(item => {
							data.push({
								name: item.name,
								value1: '知识产权数：' + item.city,
								value2: '技术合同登记额：' + item.education + '万元'
							});
						});
						ECharts.setOption({
							tooltip: {
								trigger: 'item',
								formatter: function(params) {
									var toolTiphtml = '';
									for (var i = 0; i < data.length; i++) {
										if (params.name == data[i].name) {
											toolTiphtml += data[i].value1 + '<br/>' + data[i].value2;
										}
									}
									return toolTiphtml;
								},
								textStyle: {
									color: '#cdb10d',
									fontWeight: 'bold'
								}
							},
							series: [{
								type: 'map',
								map: 'dazhou',
								label: {
									show: true,
									color: '#fff'
								},
								zoom: 1.2,
								itemStyle: {
									areaColor: '#1f71b0',
									borderColor: '#fff'
								},
								emphasis: {
									label: {
										color: '#000'
									},
									itemStyle: {
										areaColor: '#02cde6'
									}
								},
								data: data,
								select: {
									label: {
										color: '#f33'
									}
								}
							}]
						});
						window.addEventListener('resize', function() {
							ECharts.resize();
						});
					})
					.catch(() => {
						data = [];
					});
			},
			// 科技服务
			wandalist() {
				wandalist()
					.then(res => {
						this.listData3 = res.data;
					})
					.catch(() => {
						this.listData3 = [];
					});
			},
			// 技术合同
			wandclist() {
				wandclist()
					.then(res => {
						this.listData4 = res.data;
					})
					.catch(() => {
						this.listData4 = [];
					});
			},
			// 研发投入情况 - data
			loadData3(cityValue) {
				var data = [];
				this.cityData = [];
				wandkeyan()
					.then(res => {
						res.data.map(item => {
							this.cityData.push({
								id: item.id,
								value: item.city,
								label: item.city
							});
							if (cityValue == item.city) {
								data = [{
										name: '研发投入总值',
										type: 'bar',
										barGap: 0,
										itemStyle: {
											normal: {
												color: {
													type: 'linear',
													x: 0,
													y: 0,
													x2: 0,
													y2: 1,
													colorStops: [{
															offset: 0,
															color: '#01df8b'
														},
														{
															offset: 1,
															color: '#009f63'
														}
													],
													globalCoord: false
												}
											}
										},
										data: [item.onenum, item.twonum, item.therenum],
										barWidth: '28%'
									},
									{
										name: '研发投入占GDP比重',
										type: 'bar',
										itemStyle: {
											normal: {
												color: {
													type: 'linear',
													x: 0,
													y: 0,
													x2: 0,
													y2: 1,
													colorStops: [{
															offset: 0,
															color: '#3078fe'
														},
										
														{
															offset: 1,
															color: '#0149d0'
														}
													],
													globalCoord: false
												}
											}
										},
										data: [item.onenums, item.twonums, item.therenums],
										barWidth: '28%'
									}
								];
							}
						});
						this.$nextTick(function() {
							// 研发投入情况 - 图表
							this.ECharts3(data);
						});
					})
					.catch(() => {
						data = [];
					});
			},
			// 研发投入情况 - 选择城市
			selectClass(value) {
				this.cityValue = value;
				this.loadData3(this.cityValue);
			},
			// 研发投入情况 - 图表
			ECharts3(data) {
				let ECharts = this.$echarts.init(this.$refs.ECharts3);
				ECharts.setOption({
					legend: {
						top: '0%',
						textStyle: {
							color: '#02cde6'
						}
					},
					tooltip: {
						trigger: 'category',
						axisPointer: {
							lineStyle: {
								color: 'rgba(0,0,0,0)'
							}
						}
					},
					xAxis: [{
						type: 'category',
						data: ['2021', '2022', '2023'],
						axisPointer: {
							type: 'shadow'
						},
						axisLine: {
							lineStyle: {
								color: '#01a3e1'
							}
						},
						axisLabel: {
							textStyle: {
								color: '#02cde6',
								fontSize: '12'
							}
						}
					}],
					itemStyle: {
					    normal: {
					        color: {
					            type: 'linear',
					            x: 0,
					            y: 0,
					            x2: 0,
					            y2: 1,
					            colorStops: [{
					                offset: 0,
					                color: '#3078fe'
					            },
					            {
					                offset: 1,
					                color: '#0149d0'
					            }],
					            globalCoord: false
					        }
					    }
					},
					yAxis: [{
						type: 'value',
						axisLine: {
							lineStyle: {
								color: '#02cde6'
							}
						},
						axisLabel: {
							color: '#02cde6'
						}
					}],
					series: data
				});
				window.addEventListener('resize', function() {
					ECharts.resize();
				});
			}
		},
		beforeDestroy() {
			if (this.timer) {
				clearInterval(this.timer); // 在Vue实例销毁前，清除定时器
			}
		}
	};
</script>

<style lang="less" scoped>
	body {
		background-color: #01092f !important;
	}

	.governmentss {
		position: relative;
		max-width: 100%;
		width: 100%;
		height: 100%;
		margin: 0 auto;
		min-height: 100vh;
		background: #000d4a url('../../../assets/img/images/enbg.png') no-repeat;
		background-position: center top;
		background-size: 100% 100%;
		overflow: hidden;

		.container {
			width: 100%;

			.header {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100px;
				background: url('../../../assets/img/images/ptic_01.png') no-repeat;
				background-position: center top;
				background-size: 100% 100%;

				h3 {
					font-size: 38px;
					color: #fff;
					line-height: 100px;
					font-weight: bold;
					text-align: center;
				}

				p {
					position: absolute;
					right: 107px;
					top: 20%;
					font-size: 18px;
					color: #6984c1;
				}
			}

			.main {
				width: 98%;
				margin: 86px auto 0;
				display: flex;
				align-items: flex-start;
				justify-content: space-between;

				.box {
					.tbga {
						width: 100%;
						height: 304px;
						background: url('../../../assets/img/images/wana_03.png') no-repeat center;
						background-size: 100% 304px;
						overflow: hidden !important;
					}

					.tbgb {
						width: 100%;
						height: 355px;
						background: url('../../../assets/img/images/wanb_03.png') no-repeat center;
						background-size: 100% 355px;
						overflow: hidden !important;
					}

					.tabs {
						position: relative;

						/deep/ .el-select {
							margin-right: 8px;
							position: absolute;
							top: 45px;
							right: 25px;

							.el-input {
								.el-input__inner {
									width: 80px;
									height: 30px;
									padding: 0 5px;
									font-size: 14px;
									color: #2e76fd;
									line-height: 30px;
									border: 1px solid #2e76fd;
									border-radius: 7px;
									background-color: rgba(9, 48, 104, 0.82);
								}

								.el-input__suffix {
									color: #2e76fd;

									.el-input__icon {
										line-height: 30px;
									}
								}
							}
						}

						/deep/.el-tabs__header {
							width: 120px;
							margin: 0 15px 0 0;

							.el-tabs__active-bar {
								display: none;
							}

							.el-tabs__item {
								height: 14px;
								padding: 0 8px;
								font-size: 12px;
								color: #fff;
								line-height: 14px;
							}

							#tab-0 {
								border-right: 1px solid #fff;
							}

							.el-tabs__item.is-active {
								color: #02cde6;
							}

							.el-tabs__nav-wrap::after {
								height: 0;
							}
						}

						.lines {
							width: calc(100% + 6px);
							height: calc(100% + 6px);

							i {
								display: inline-block;
								width: 10px;
								height: 10px;
								position: absolute;
								z-index: 5;

								&:nth-child(1) {
									top: -3px;
									left: -3px;
									border-top: 3px solid #02cde6;
									border-left: 3px solid #02cde6;
								}

								&:nth-child(2) {
									top: -3px;
									right: -3px;
									border-top: 3px solid #02cde6;
									border-right: 3px solid #02cde6;
								}

								&:nth-child(3) {
									bottom: -6px;
									left: -3px;
									border-left: 3px solid #02cde6;
									border-bottom: 3px solid #02cde6;
								}

								&:nth-child(4) {
									bottom: -6px;
									right: -3px;
									border-right: 3px solid #02cde6;
									border-bottom: 3px solid #02cde6;
								}
							}
						}

						.tabCon {
							width: 100%;
							height: 100%;
							.headline {
								padding: 28px 25px 10px;
								display: flex;
								align-items: center;

								.bangpic {
									width: 25px;
									height: 30px;
									flex-shrink: 0;
								}

								h3 {
									padding-left: 10px;
									font-size: 18px;
									color: #0199e8;
									font-weight: bold;
								}
							}

							.list {
								overflow: hidden;

								ul {
									height: 100%;
									padding: 0 5px;

									li {
										width: 91.6%;
										margin: 0 auto;

										&:nth-child(2n) {
											background-color: #102e4a;
										}

										i {
											display: inline-block;
											width: 8px;
											height: 8px;
											margin-left: 4px;
											border-radius: 50%;
											background-color: #e2dd29;
											margin-right: 5px;
										}

										p {
											max-width: 80%;
											font-size: 14px;
											color: #ffffff;
											font-weight: bold;
											text-overflow: ellipsis;
											white-space: nowrap;
											overflow: hidden;
										}

										span {
											font-size: 12px;
											color: #3a8fff;
											padding-left: 22px;
											flex-shrink: 0;
										}
									}
								}

								.select {
									display: flex;
									align-items: center;
									justify-content: flex-end;


								}
							}
						}
					}

					.one {
						li {
							height: 36px;
							display: flex;
							align-items: center;
							justify-content: space-between;

							p {
								width: 83%;
								color: #02cde6;
								line-height: 36px;
							}

							span {
								color: #02cde6;
							}
						}
					}

					.two {
						li {
							padding-bottom: 3px;

							.twoTit {
								height: 32px;
								display: flex;
								align-items: center;

								p {
									width: 96%;
									color: #90d0ff;
									text-indent: 4px;
								}
							}

							span {
								display: block;
								font-size: 12px;
								color: #237cbe;
								line-height: 1.5;
							}
						}
					}
				}

				.leftCon,
				.rightCon {
					width: 27%;
					flex-shrink: 0;
				}

				.centerCon {
					width: 45%;
					height: 881px;
					padding-top: 40px;
				}
			}
		}
	}

	/deep/.el-select-dropdown__wrap {
		margin-right: 0;
	}
	@media screen and (max-width: 1200px) {
		.governmentss .container .header h3{
			font-size: 1.4rem;
		}
	}
</style>
<style>
	#ECharts2 {
		div {
			width: 100% !important;
		}

		canvas {
			width: 100%;
		}
	}
</style>