<template>
	
	<div class="government">
		<kefuright></kefuright>
		<!--轮播图-->
		<home-swiper :imgList="imgList"></home-swiper>
		<!-- 政府云 -->
		<div class="zhengbox animate__animated animate__fadeInUp">
			<div class="zhengtop ">
				<img src="../../../assets/img/images/tl_06.png" class="zhengpic" alt="" />
				<div class="zhengtitle">政府云</div>
				<img src="../../../assets/img/images/tr_07.png" class="zhengpic" alt="" />
			</div>
			<div class="qifu">
				<div class="qili" :class="{ qiactive: isActive == index }" v-for="(item, index) in yunList" :key="index"
					@click="yuntab(index)">
					{{ item.name }}
				</div>
			</div>
			<div class="banxin" v-if="isActive === 0">
				<div class="img_box_two">
					<router-link
						:to="{ path: '/ServiceCloud' }"
						target="_blank"
						><serviceCloud
							v-if="isChildUpdate1"
						></serviceCloud
					></router-link>
				</div>
			</div>
			<div class="banxin" v-if="isActive === 1">
				<div class="img_box_two">
				                            <!-- <img @click="handlePictureCardPreview($event)" src="../../../assets/img/zhengfuyun/innovation-img-2.jpg" alt="" /> -->
					<router-link
						:to="{ path: '/EnterpriseCloud' }"
						target="_blank"
						><enterpriseCloud
							v-if="isChildUpdate2"
						></enterpriseCloud
					></router-link>
				</div>
			</div>
		</div>
		<!-- 政策大汇集 -->
		<div class="qiyebox">
			<div class="qiyetop">
				<img src="../../../assets/img/images/tl_06.png" class="zhengpic" alt="" />
				<div class="zhengtitle">政策大汇集</div>
				<img src="../../../assets/img/images/tr_07.png" class="zhengpic" alt="" />
			</div>
			<div class="banxin">
				<div class="xiangbox">
					<div class="xileft">
						<div class="xili" @click="qitab(item,index)" v-for="(item , index) in qiList"
							:class="{ qiActive: qiActive == index }">
							<div class="xitop">
								<!-- <img :src="qiActive == index ? item.chose : item.nochose" class="xipic" alt="" /> -->
								<div class="xititile">
									{{item.name}}
								</div>
							</div>
							<div class="xiline"></div>
						</div>
					</div>
					<div class="xiright">
						<div class="xirtop">
							<img src="../../../assets/img/images/du_03.png" class="dupic" alt="" />
							<div class="duyou">
								<div class="dutitle oneline">
									{{ title }}
								</div>
								<div class="dutxt twolines" v-html="details"></div>
								<!-- <div class="liaotxt">
									了解详情 >
								</div> -->
							</div>
						</div>
						<div class="wenbox">
							<div class="line"></div>
							<div class="wenli" v-for="(todo, index) in todos" @click="policy(todo.id)">
								<img src="../../../assets/img/images/zicon_07.png" class="wenpic" alt="" />
								<div class="wentitle oneline">
									{{ todo.title }}
								</div>
								<div class="wentime">
									{{ todo.created_at | formatDate }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 考核或评估 -->
		<div class="zhengbox">
			<div class="zhengtop">
				<img src="../../../assets/img/images/tl_06.png" class="zhengpic" alt="" />
				<div class="zhengtitle">考核或评估</div>
				<img src="../../../assets/img/images/tr_07.png" class="zhengpic" alt="" />
			</div>
			<div class="qifu">
				<div class="qili" :class="{ qiactive: ispinggu == index }" v-for="(item, index) in guList" :key="index"
					@mousemove="gutab(item,index)">
					{{ item.name }}
				</div>
			</div>
			<div class="banxin" v-for="(item,index) in caiList" :key="index" @click="tabxq(item.id)">
				<div class="guanbox">
					<div class="guanleft">
						<div class="gtitle oneline">
							{{item.name}}
						</div>
						<div class="gtxt twolines" v-html="item.content">

						</div>
						<div class="gubox">
							<div class="guli">
								<img src="../../../assets/img/images/gu_07.png" class="gupic" alt="" />
								<div class="gutxt">
									服务响应时间：<span>{{item.ftime}}</span>
								</div>
							</div>
							<div class="guli">
								<img src="../../../assets/img/images/gub_07.png" class="gupic" alt="" />
								<div class="gutxt">
									服务费用：<span>{{item.costs}}</span>元
								</div>
							</div>
							<div class="guli">
								<img src="../../../assets/img/images/guc_07.png" class="gupic" alt="" />
								<div class="gutxt">
									联系电话：<span>{{item.phone}}</span>
								</div>
							</div>
						</div>
					</div>
					<img :src="urlImg+ item.img" alt="" class="guyou" />
				</div>
			</div>
		</div>
		<!-- 任务与活动 -->
		<div class="wubox">
			<div class="qiyetop">
				<img src="../../../assets/img/images/tl_06.png" class="zhengpic" alt="" />
				<div class="zhengtitle">任务与活动</div>
				<img src="../../../assets/img/images/tr_07.png" class="zhengpic" alt="" />
			</div>
			<div class="wufu">
				<div class="wuli" :class="{ wuactive: isRenwu == index }" v-for="(item, index) in wuList" :key="index"
					@mousemove="wutab(item,index)">
					{{ item.name }}
				</div>
			</div>
			<div class="banxin">
				<div class="caibox">
					<div class="caili" v-for="(i, index) in list" :key="index" @click="xq(i.id)">
						<div class="caititle oneline">
							{{ i.title }}
						</div>
						<img :src="urlImg + i.cover" class="caipic" />
						<div class="caitxt twolines" v-html="i.content">

						</div>
						<!-- <div class="cailan" @click="xq(i.id)">
							了解更多 >
						</div> -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import kefuright from '../../../components/common/kefu';
	import {
		Zatype,
		Zactivity
	} from "@/common/js/api";
	import HomeSwiper from '../../../components/common/Swiper';
	import { formatDate } from "../../../common/js/times"; //时间
	import {
		inType
	} from "@/common/js/api";
	import {
		InnovateList
	} from "@/common/js/api";
	import {
		urlImg
	} from "@/common/js/url";
	import {
		Financing,
		PolicyType
	} from "../../../common/js/api";
	import {
		zhfu
	} from '../../common/js/api';
	import serviceCloud from '../../zhengfuyun/base/serviceCloud';
	import enterpriseCloud from '../../zhengfuyun/base/enterpriseCloud';
	export default {
		name: 'zhengfuyunForm',
		components: {
			HomeSwiper,
			serviceCloud,
			enterpriseCloud,
			kefuright
		},
		data() {
			return {
				isChildUpdate2: true,
				isChildUpdate1: true,
				// 政府云切换
				yunList: [{
					id: 1,
					name: '服务监测云'
				}, {
					id: 2,
					name: '企业监测云'
				}],
				isActive: 0,
				// 政策大汇集
				qiList: [],
				todos: [],
				type: "",
				title: "",
				details: "",
				qiActive: 0,
				// 考核或评估
				guList: [],
				typeId: 308,
				ispinggu: 0,
				caiList: [],
				urlImg: '',
				// 任务与活动
				wuList: [],
				list: [],
				qieId: '',
				isRenwu: 0,
			};
		},
		created() {
			this.urlImg = urlImg();
			this.getTab();
			this.active()
			this.getren()
			this.getCom()
			this.PolicyType()
		},
		filters: {
			formatDate(time) {
				time = time * 1000;
				let date = new Date(time);
				return formatDate(date, "yyyy-MM-dd");
			},
		},
		methods: {
			tabxq(id){
			       this.$router.push({
			        path: "/tab",
			        query: {
			          id: id,
			        },
			      });
			    },
			// 大屏1
			goTodapinga() {
				this.$router.push({
					path: '/ServiceCloud'
				});
			},
			// 大屏2
			goTodapingb() {
				this.$router.push({
					path: '/enterpriseCloud'
				});
			},
			// 政策大汇集
			qitab(item, index) {
				this.qiActive = index;
				this.type = item.id
				this.getFinancing();
			},
			// 前往政策详情
			    policy(id) {
			      this.$router.push({
			        path: "/policy",
			        query: {
			          id: id,
			        },
			      });
			    },
			PolicyType() {
				PolicyType({})
					.then((res) => {
						
						this.qiList = res.data
						this.type = this.qiList[0].id;
						this.getFinancing();
					})
					.catch(() => {});
			},
			getFinancing() {
				Financing({
						type: this.type,
						page: 1,
						limits: 10,
					})
					.then((res) => {
						console.log(res, '1111111111');
						var itemss = res.data.data;
						this.todos = Array.from(itemss).reverse()
						
						this.todos[0].details = this.todos[0].details.replace(
							/<\/?(img)[^>]*>/gi,
							""
						);
						this.title = this.todos[0].title;
						this.details = this.todos[0].details;
					})
					.catch(() => {});
			},
			// 考核或评估
			getTab() {
				inType()
					.then((res) => {
						this.guList = res.data;
					})
					.catch(() => {});
			},
			gutab(item, index) {
				this.ispinggu = index;
				this.typeId = item.id
				this.active()
			},
			active() {
				InnovateList({
					type: this.typeId
				}).then((res) => {
					this.caiList = res.data.data


				}).catch(() => {})

			},
			// 政府云切换
			yuntab(index) {
				this.isActive = index;
			},


			// 任务与活动
			wutab(item, index) {
				this.isRenwu = index;
				this.qieId = item.id
				this.getCom()
			},
			// 任务与活动 tab内容
			getCom() {
				Zactivity({
					limits: 3,
					page: 1,
					type: this.qieId,
				}).then((res) => {
					this.list = res.data.data;
				});
			},
			getren() {
				Zatype()
					.then((res) => {

						this.wuList = res.data;
					})
					.catch(() => {});
			},
			// 跳转详情
			xq(id) {
				this.$router.push({
					path: "/tack",
					query: {
						id: id,
					},
				});
			},
		}
	};
</script>

<style lang="less" scoped>
	.governmentss{
		width: 100%;
		margin-bottom: 60px !important;
	}
	.img_box_two {
	    width: 100%;
	    height: 500px;
	    border-radius: 5px;
		overflow: hidden;
	    background-color: #eee;
	    cursor: pointer;
	}
	.banxin {
		width: 1400px;
		margin: 0 auto;
	}

	.qiyetop {
		display: flex;
		align-items: center;
		justify-content: center;
		padding-bottom: 60px;

		.zhengpic {
			width: 118px;
			height: 27px;
			flex-shrink: 0;
		}

		.zhengtitle {
			font-size: 36px;
			color: #222222;
			font-weight: bold;
			padding: 0 35px;
		}

		.zhengbai {
			font-size: 36px;
			color: #ffffff;
			font-weight: bold;
			padding: 0 35px;
		}
	}

	.zhengbox {
		background-color: #ffffff;
		padding: 60px 0;

		.pb60 {
			padding-bottom: 60px !important;
		}

		.zhengtop {
			display: flex;
			align-items: center;
			justify-content: center;
			padding-bottom: 48px;

			.zhengpic {
				width: 118px;
				height: 27px;
				flex-shrink: 0;
			}

			.zhengtitle {
				font-size: 36px;
				color: #222222;
				font-weight: bold;
				padding: 0 35px;
			}
		}

		.qifu {
			border-bottom: 1px solid #e5e5e5;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 50px;

			.qili {
				font-size: 22px;
				color: #333333;
				margin: 0 50px;
				border-bottom: 3px solid transparent;
				padding-bottom: 14px;
				cursor: pointer;
			}

			.qiactive {
				border-bottom: 3px solid #0289f4;
				color: #0289f4;
			}
		}

		.daping {
			width: 100%;
			height: 690px;
			overflow: hidden;
		}
	}

	.qiyebox {
		background-color: #f6f6f6;
		padding: 60px 0;

		.xiangbox {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;

			.xileft {
				width: 260px;
				flex-shrink: 0;
				background-color: #ffffff;

				.xili:last-child {
					.xiline {
						height: 0px !important;
						background-color: #d5d6d9;
						margin: 0 auto;
					}
				}

				.xili {
					transition: 0.5s ease-in-out;
					height: 120px;
					text-align: center;
					cursor: pointer;
					position: relative;

					.xitop {
						display: flex;
						align-items: center;
						justify-content: center;
						height: 120px;

						.xipic {
							width: 23px;
							height: 23px;
						}

						.xititile {
							font-size: 22px;
							color: #888888;
							padding-left: 12px;
						}
					}

					.xiline {
						width: 222px;
						height: 1px;
						background-color: #d5d6d9;
						margin: 0 auto;
					}
				}
				.xili:hover {
					background-color: #0289f4;
					box-shadow: 0 0 8px #77c0f9;
				
					.xitop {
				
						.xititile {
							color: #ffffff !important;
						}
					}
				
					.xiline {
						height: 0 !important;
					}
				
					&::before {
						transition: all 0.5s;
						content: "";
						position: absolute;
						right: -9px;
						top: 50%;
						margin-top: -0.2rem;
						border-top: solid 0.425rem transparent;
						border-bottom: solid 0.425rem transparent;
						border-left: #0289f4 0.5rem solid;
					}
				}
				.qiActive {
					background-color: #0289f4;
					box-shadow: 0 0 8px #77c0f9;

					.xitop {

						.xititile {
							color: #ffffff !important;
						}
					}

					.xiline {
						height: 0 !important;
					}

					&::before {
						transition: all 0.5s;
						content: "";
						position: absolute;
						right: -9px;
						top: 50%;
						margin-top: -0.2rem;
						border-top: solid 0.425rem transparent;
						border-bottom: solid 0.425rem transparent;
						border-left: #0289f4 0.5rem solid;
					}
				}
			}

			.xiright {
				width: 1120px;
				height: 480px;
				background-color: #ffffff;

				.xirtop {
					padding:20px;
					border-bottom: 1px solid #eeeeee;
					display: flex;
					align-items: center;

					.dupic {
						width: 180px;
						height: 120px;
						flex-shrink: 0;
					}

					.duyou {
						padding-left: 20px;
						width: 82%;

						.dutitle {
							font-size: 24px;
							color: #222222;
							font-weight: bold;
							padding-bottom: 10px;
						}

						.dutxt {
							font-size: 16px;
							color: #888888;
							line-height: 28px;
							margin-bottom: 15px;
						}

						.liaotxt {
							font-size: 16px;
							color: #0289f4;
							cursor: pointer;
						}
					}
				}

				.wenbox {
					padding: 25px 20px 0;
					display: flex;
					align-items: center;
					justify-content: space-between;
					flex-wrap: wrap;
					position: relative;

					.line {
						position: absolute;
						top: 30px;
						left: 50%;
						width: 1px;
						background-color: #eeeeee;
						height: 235px;
					}

					.wenli {
						width: 510px;
						padding-bottom: 37px;
						display: flex;
						cursor: pointer;
						align-items: center;
						justify-content: space-between;

						.wenpic {
							width: 15px;
							height: 15px;
							flex-shrink: 0;
						}

						.wentitle {
							flex-grow: 1;
							font-size: 18px;
							color: #000000;
							padding: 0 25px 0 13px;
						}

						.wentime {
							font-size: 14px;
							color: #888888;
							flex-shrink: 0;
						}
					}

					.wenli:hover {
						.wentitle {
							color: #0289f4;
						}
					}
				}
			}
		}
	}

	.guanbox {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.guanleft {
			width: 720px;

			.gtitle {
				font-size: 30px;
				color: #333333;
				font-weight: bold;
				padding-bottom: 45px;
				border-bottom: 1px solid #eeeeee;
			}

			.gtxt {
				font-size: 16px;
				color: #888888;
				line-height: 28px;
				margin: 40px 0 75px;
			}

			.gubox {
				display: flex;
				align-items: center;

				.guli {
					text-align: center;
					padding-right: 45px;

					.gupic {
						width: 43px;
						height: 39px;
						margin: 0 auto;
					}

					.gutxt {
						font-size: 16px;
						color: #888888;
						padding-top: 20px;

						span {
							font-size: 24px;
							color: #ff4200;
						}
					}
				}
			}
		}

		.guyou {
			width: 618px;
			height: 410px;
		}
	}

	.wubox {
		padding: 60px 0;
		background-color: #f6f6f6;

		.wufu {
			border-bottom: 1px solid #e5e5e5;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 50px;

			.wuli {
				font-size: 22px;
				color: #333333;
				margin: 0 55px;
				border-bottom: 3px solid transparent;
				padding-bottom: 14px;
				cursor: pointer;
			}

			.wuli:last-child {
				margin-right: 0;
			}

			.wuli:first-child {
				margin-left: 0;
			}

			.wuactive {
				border-bottom: 3px solid #0289f4;
				color: #0289f4;
			}
		}

		.caibox {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.caili {
				padding: 0 30px;
				width: 430px;
				height: 375px;
				background-color: #ffffff;
				box-shadow: 0 0 5px #dddddd;
				cursor: pointer;
				transition: all 0.5s;
				.caititle {
					font-size: 24px;
					color: #222222;
					font-weight: bold;
					padding: 30px 0;
				}

				.caipic {
					width: 100%;
					height: 180px;
				}

				.caitxt {
					font-size: 14px;
					color: #888888;
					padding: 23px 0 0;
					margin-bottom: 56px;
					line-height: 28px;
				}

				.cailan {
					cursor: pointer;
					color: #0289f4;
					font-size: 16px;
				}
			}
			.caili:hover{
				transform: translateY(-5%);
				background-color: #0289f4;
				.caititle{
					color: #ffffff;
				}
				.caitxt{
					color: #ffffff;
				}
			}
		}
	}
	@media screen and (max-width: 1200px) {
		.banxin{
			width: 98%;
			margin: 0 auto;
			overflow: hidden;
		}
		.zhengbox .zhengtop .zhengtitle{
			font-size: 3rem;
		}
		.zhengbox .qifu .qili{
			font-size: 1.8rem;
		}
		.qiyetop .zhengtitle{
			font-size: 3rem;
		}
		.qiyebox .xiangbox{
			flex-wrap: wrap;
		}
		.qiyebox .xiangbox .xileft{
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-wrap: wrap;
		}
		.qiyebox .xiangbox .xileft .xili{
			width: 50%;
			border: 1px solid #eeeeee;
		}
		.qiyebox .xiangbox .xileft .qiActive::before{
			display: none !important;
		}
		.qiyebox .xiangbox .xileft .xili .xitop .xititile{
			font-size: 1.8rem;
		}
		.qiyebox .xiangbox .xileft .xili .xiline{
			display: none;
		}
		.qiyebox .xiangbox .xiright .xirtop .dupic{
			width: 12rem;
			height: 9.5rem;
		}
		.qiyebox .xiangbox .xiright .xirtop .duyou .dutitle{
			font-size: 1.8rem;
			width: 80%;
		}
		.qiyebox .xiangbox .xiright .xirtop .duyou .dutxt { 
			font-size: 1.4rem !important;
			width: 80% !important;
		}
		.qiyebox .xiangbox .xiright .xirtop .duyou .dutxt p span{
			font-size: 1.4rem !important;
		}
		.qiyebox .xiangbox .xiright .xirtop .duyou .liaotxt{
			font-size: 1.4rem;
		}
		.qiyebox .xiangbox .xiright{
			width: 100%;
			height: auto;
		}
		.qiyebox .xiangbox .xiright .wenbox .wenli{
			width: 100%;
			padding: 1rem 0 1.5rem;
		}
		.qiyebox .xiangbox .xiright .wenbox .wenli .wenpic{
			width: 1.5rem;
			height: 1.5rem;
		}
		.qiyebox .xiangbox .xiright .wenbox .wenli .wentitle{
			font-size: 1.6rem;
		}
		.qiyebox .xiangbox .xiright .wenbox .wenli .wentime{
			font-size: 1.4rem;
		}
		.guanbox{
			flex-wrap: wrap;
		}
		.guanbox .guanleft{
			width: 100%;
			padding-bottom: 3rem;
		}
		.guanbox .guanleft .gtitle{
			font-size: 2.2rem;
		}
		.guanbox .guanleft .gtxt{
			width: 70%;
			font-size: 1.6rem;
			line-height: 2.6rem;
			padding-bottom: 0;
			margin-bottom: 3rem;
		}
		.guanbox .guanleft .gubox .guli{
			padding-right: 1rem;
		}
		.guanbox .guanleft .gubox .guli .gupic{
			width: 4.3rem;
			height: 3.9rem;
		}
		.guanbox .guanleft .gubox .guli .gutxt{
			font-size: 1.4rem;
		}
		.guanbox .guanleft .gubox .guli .gutxt span{
			font-size: 1.6rem;
		}
		.guanbox .guyou{
			width: 100%;
			height: auto;
		}
		.wubox .wufu{
			flex-wrap: wrap;
			justify-content: flex-start;
		}
		.wubox .wufu .wuli{
			margin: 0;
			width: 33.33333%;
			line-height: 4rem;
			font-size: 1.4rem;
			background-color: #ffffff;
			height: 4rem;
			text-align: center;
			border: 1px solid #eeeeee;
		}
		.wubox .wufu .wuactive{
			color: #ffffff;
			background-color: #0289f4;
		}
		.wubox .caibox{
			flex-wrap: wrap;
		}
		.wubox .caibox .caili{
			width: 49%;
			margin-bottom: 1rem;
			height: 20rem;
		}
		.wubox .caibox .caili .caititle{
			font-size: 1.9rem;
		}
		.wubox .caibox .caili .caipic{
			height: 13rem;
		}
		.wubox .caibox .caili .caitxt{
			font-size: 1.5rem;
			line-height: 2.2rem;
			display: none;
		}
		.wubox .caibox .caili .cailan{
			font-size: 1.6rem;
		}
	}
</style>